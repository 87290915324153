import { CopyrightOutlined } from '@ant-design/icons';
import React from 'react';
import './index.less';

export default function Footer() {
  return (
    <div className="footer-container">
      <div className="container footer-bottom">
        <div className="footer-content">
          <div className="footer-content-item">
            <div className="fci-title notranslate">xBack VPN</div>
            <div className="fci-list">
              <div className="fcil-item">
                <a
                  href="https://blog.xback.io/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Next-gen VPN
                </a>
              </div>
              <div className="fcil-item">
                <a href="/vip">
                  Why &nbsp;
                  <span className="notranslate">xBack</span>
                </a>
              </div>

              <div className="fcil-item">
                <a href="/public/rule.html">Invite Guide</a>
              </div>
            </div>
          </div>

          <div className="footer-content-item">
            <div className="fci-title">TRY NOW</div>
            <div className="fci-list">
              <div className="fcil-item">
                <a
                  href="https://download.xback.io/download/ios/latest"
                  target="_blank"
                  rel="noreferrer"
                >
                  VPN for iPhone
                </a>
              </div>
              <div className="fcil-item">
                <a
                  href="https://download.xback.io/download/android_official/latest"
                  target="_blank"
                  rel="noreferrer"
                >
                  VPN for Android
                </a>
              </div>
              <div className="fcil-item">
                <a
                  href="https://download.xback.io/download/android/latest"
                  target="_blank"
                  rel="noreferrer"
                >
                  VPN on Google Play
                </a>
              </div>
              <div className="fcil-item">
                <a
                  href="https://download.xback.io/download/macos/latest"
                  target="_blank"
                  rel="noreferrer"
                >
                  MAC VPN
                </a>
              </div>
              <div className="fcil-item">
                <a
                  href="https://download.xback.io/download/windows/latest"
                  target="_blank"
                  rel="noreferrer"
                >
                  Windows VPN
                </a>
              </div>
            </div>
          </div>

          <div className="footer-content-item">
            <div className="fci-title">Support</div>
            <div className="fci-list">
              <div className="fcil-item">
                <a href="/support">Submit a ticket</a>
              </div>
              <div className="fcil-item">
                <a href="mailto:go.xback@gmail.com">Contact us</a>
              </div>
            </div>
          </div>

          <div className="footer-content-item">
            <div className="fci-title">And more</div>
            <div className="fci-list">
              <div className="fcil-item">
                <a
                  href="https://www.xback.io/privacy-policy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </div>
              <div className="fcil-item">
                <a
                  href="https://www.xback.io/terms-of-use/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Service
                </a>
              </div>
              {/* <div className="fcil-item">
                <a href="">Cookie Preferences</a>
              </div> */}
            </div>
          </div>
        </div>
        <div className="footer-right">
          Copyright
          <CopyrightOutlined style={{ margin: '4px 5px' }} />
          {new Date().getFullYear()}
          &nbsp; <span className="notranslate">xBack</span> Inc. All rights
          reserved.
        </div>
      </div>
    </div>
  );
}
